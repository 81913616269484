const checkoutEs = {
    completeYouInformation: 'Complete su información',
    beSureToFillInTheRequiredFields: 'Asegúrese de rellenar los campos obligatorios',
    name: 'Nombre',
    surname: 'Apellido',
    email: 'Email',
    phone: 'Teléfono',
    iAgreeToThese: 'Estoy de acuerdo con estos',
    termsAndConditions: 'Terminos y condiciones',
    proceedToPayment: 'Proceder a pagar',
    summary: 'Resumen',
    date: 'Fecha',
    totalGuest: 'Total de personas',    
    adults: 'Adultos',
    children: 'Niños',  
    total: 'Total', 
    taxesAreIncluded: 'Impuestos incluidos',
    youHaveNotSelectedAnyItem: 'No tiene seleccionado ningun elemento'

}

export default checkoutEs