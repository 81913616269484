const productsEs = {
    search: 'Buscar',
    categories: 'Categorías',
    whereYouWantToGo: 'Dónde quiere ir',
    allDestinations: 'Todos los destinos',
    date: 'Fecha',
    noPersons: 'No. Personas',
    adultsSearch: 'Adultos',
    childrenSearch: 'No. niños',
    general: 'General',
    startingAt: 'Desde',
    showMore: 'Ver más',
    totalPrice: 'Precio total',
    book: 'Reservar',
    noProductsFound: 'No se encontraron resultados',
    exploreNow:'Explorar ahora',
    // para cards de producst
    adults: '{count} adulto | {count} adultos',
    children: '{count} niño | {count} niños',
    
}

export default productsEs
