const headerFooterEn = {
    home: 'Home',
    aboutUs: 'About Us',
    experiences: 'Experiences',
    linkHome:'https://www.marholidayscaribe.com/',
    linkAboutUs:'https://www.marholidayscaribe.com/#about-us',
    linkExperiences:'https://www.marholidayscaribe.com/#experiencies',
    copyRightText: 'Ⓒ {year}, MAR HOLIDAYS CARIBE, DESIGN BY THE FIVES CORP. IN PLAYA DEL CARMEN.',    
}

export default headerFooterEn