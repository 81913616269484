const headerFooterEs = {
    home: 'Inicio',
    aboutUs: 'Sobre Nosotros',
    experiences: 'Experiencias',
    linkHome:'https://www.marholidayscaribe.com/es',
    linkAboutUs:'https://www.marholidayscaribe.com/es#about-us',
    linkExperiences:'https://www.marholidayscaribe.com/es#experiencies',
    copyRightText: 'Ⓒ {year}, MAR HOLIDAYS CARIBE, DISEÑADO POR THE FIVES CORP. EN PLAYA DEL CARMEN.',    
    
}

export default headerFooterEs