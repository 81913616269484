//start module state Hotels
export function setHotels(state, hotels) {
    state.hotels = hotels
}
//start module state Categories
export function setCategories(state, categories) {
    const { categoryServices, hotelServices, onlyCategories, withSubCategories } = categories
    state.categories.categoryServices = categoryServices
    state.categories.hotelServices = hotelServices
    state.categories.onlyCategories = onlyCategories
    state.categories.withSubCategories = withSubCategories    
}


export function setSelectedCategory(state, data ){
    // console.log(data)
    const {idCategory, idSubCategory } = data 
    state.selectedCategory.idCategory = idCategory
    state.selectedCategory.idSubCategory = idSubCategory

} 
 
//start module state Currencies
export function setCurrencies(state, currencies) {
    state.currencies = currencies
}

export function setLanguages(state, languages){
    state.languages = languages   
}

export function setSelectedDestination(state, destination ){
    state.selectedDestination = destination
} 

export function setTourDestinations(state, tourDestinations) {
    state.tourDestinations = tourDestinations
}









