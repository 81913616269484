const itemDetailEn = {
    list: 'List',
    detail: 'Detail',
    checkout: 'Checkout',
    productPolicies: 'Search',
    date: 'Date',
    noPersons: 'No. Persons',
    adults: 'Adults',
    children: 'Children',  
    total: 'Total',
    taxesAreIncluded: 'Taxes are included',
    proceedToCheckout: 'Proceed to checkout',
    exploreNow: 'Explore Now',
    noResultsFound: 'No results found',
    thePageYouWereLookingForDoesNotExist: 'The page you were looking for does not exist. Please try again, or take a look at our list of results.',
    goToOurList: 'Go to our list',

}

export default itemDetailEn