const thankYouEn = {
    pageNotFound: 'Page not Found',
    thePageYouAreLookingForDoesNotExist: 'Sorry, the page you are looking for does not exist.',
    goToOurList: 'Go to our list',
    thankYou: 'Thank You',    
    thanYouForYourPayment: 'Thank you for your payment. We will get in touch with you soon',
    backToHome: 'Back to home',
    thanYouForYourPreference: 'Thank you for your preference, your reservation is pending payment.',
    thanYouForYourPreferencePaymentDenied: 'Thank you for your preference, your reservation is pending because the payment was denied.',      
}

export default thankYouEn

