export default {
    categories:{       
        hotelServices: [],
        onlyCategories: [],
        withSubCategories: [],
        categoryServices: [],
    },    
    currencies:[],      
    hotels:[],      
    languages:[], 
    tourDestinations:[],
    selectedCategory: {
        idCategory: null,
        idSubCategory: null,        
    },
    selectedDestination: null,
}