const paymentEs = {
    pageNotFound: 'Página no encontrada.',
    thePageYouAreLookingForDoesNotExist: 'Lo sentimos, la página que estás buscando no existe.',
    backToHome: 'Volver al inicio',    
    creditCardPayment: 'Pagar con tarjeta',
    contactCenterPayment: 'Pagar vía telefónica',
    contactCenterPaymentTitle: 'Realizar el pago mediante nuestro contact center.',
    contactCenterPaymentParagraph: 'Nuestros datos de contacto',
    theOrderNumberIs: 'El número de orden es'
    
}

export default paymentEs