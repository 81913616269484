const paymentEn = {
    pageNotFound: 'Page not found',
    thePageYouAreLookingForDoesNotExist: 'Sorry, the page you are looking for does not exist.',
    backToHome: 'Back to home',    
    creditCardPayment: 'Pay by Credit/Debit Card',
    contactCenterPayment: 'Pay by telephone',
    contactCenterPaymentTitle: 'Make the payment with our contact center.',
    contactCenterPaymentParagraph: 'Our contact information',
    theOrderNumberIs: "The order number is",
    

}

export default paymentEn