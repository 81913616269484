export function setUser(state, user){ //se ejecuta una vez el usaurio haga login
    state.user = user    
    state.isLogged = true    
}

export function logout(state){
    state.user = null
    state.isLogged = false     
}

export function setDataQueryParams(state, queryParams) {
    state.queryParams = queryParams
}

export function setNumberChildren(state, number) {
    state.queryParams.children = number
}

export function setNumberAdults(state, number) {
    state.queryParams.adults = number
}

export function setDateInQueryParams(state, date) {
    state.queryParams.date = date
}

export function setDestinationInQueryParams(state, destination) {
    state.queryParams.destination = destination
}
export function setCategoryQueryParam(state, category) {    
    state.queryParams.category = category
    delete state.queryParams.subCategory
}

export function setSubCategoryQueryParam(state, idSubCategory) {
    state.queryParams.subCategory = idSubCategory
    delete state.queryParams.category
}

export function setLangApplication(state, lang) {
    state.lang = lang    
}