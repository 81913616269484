import { stringAleatorio } from "@/helpers/helpers"

export function setCurrencyCustomer(state, currency) {
    state.customerCurrency = currency
}

export function setCategorySelected(state, category) {
    state.categorySelected = category
}

export function addProductInCart(state, products) {
    state.cart = [...state.cart, ...products ]
   
}

export function removeProductInCart(state, uuid ) {
    state.cart = state.cart.filter( item => item.uuid !== uuid )    
}

export function setLanguageCustomer(state, lang){
    state.customer.language = lang    
}

export function setBlackoutsDateProduct(state, payload) {
    const { uuid, blackoutDates , maxDate  } =  payload
    const idx = state.cart.findIndex( item => item.uuid === uuid ) 
    state.cart[idx].blackoutDates.disable = blackoutDates    
    state.cart[idx].blackoutDates.maxDate = maxDate    
}

export function setOperationDateProduct(state, payload) {
    const { uuid, date } =  payload
    const idx = state.cart.findIndex( item => item.uuid === uuid ) 
    state.cart[idx].operationdate = date 
    setIfRequiredFieldsFilled(state)
}


export function setIfRequiredFieldsFilled(state) {
    state.cart.forEach((item) => {
        item.requiredFieldsFilled = false
        if (item.categoryName == "Transfer") {
            if (
                item.operationdate && item.operationdate != '' &&
                item.typeDiscount != 'Incluido en el paquete' &&
                item.itinerario.ifrom && item.itinerario.ifrom != '' &&
                item.itinerario.ito && item.itinerario.ito != '' &&
                item.itinerario.pax && item.itinerario.pax != '' &&
                item.itinerario.trip && item.itinerario.trip != ''
            ) {
                item.requiredFieldsFilled = true
            }

            if (item.extrapax != false) {
                item.requiredFieldsFilled = true
            }
        }

        if (item.categoryName == "Flights") {
            if (
                item.operationdate && item.operationdate != ''

            ) {
                item.requiredFieldsFilled = true
            }
        }
        if (item.categoryName == "Reservas") {
            if (
                item.NamePrefix && item.NamePrefix != '' &&
                item.GivenName && item.GivenName != '' &&
                item.Surname && item.Surname != '' &&
                item.PhoneNumber && item.PhoneNumber != '' &&
                item.Email && item.Email != '' &&
                item.term && item.term != null &&
                !item.requiresAuthorization

            ) {
                item.requiredFieldsFilled = true
            }
        }
        if (item.categoryName != "Transfer" && item.categoryName != "Flights" && item.categoryName != "Reservas") {
            if (item.operationdate && item.operationdate != '' && item.typeDiscount != 'Incluido en el paquete') {
                item.requiredFieldsFilled = true
            }
        }
        if (item.typeDiscount == 'Incluido en el paquete' && item.isIncludenIn && item.operationdate) {
            item.requiredFieldsFilled = true            
        }
    })
}


export function setLoadingProcess(state, bool) {
    state.isLoadingProcess = bool
}

//seteo del canal de venta
export function setCanalVenta(state, canalVenta) {
    state.canalventa = canalVenta    
}

//seteo del punto de venta
export function setPuntoDeVenta(state, puntoDeVenta) {
    state.puntoventa = puntoDeVenta
}
//limpio el punto de venta
export function unsetPuntoDeVenta(state) {
    state.puntoventa = null
}
//pongo el currency default para el client
export function setCurrency(state, currency) {
    state.customer.currency = currency
}


export function setNoteItem(state, data) {
    const { uuid, notaInterna, notaCliente } = data
    let item = state.cart.find(item => item.uuid === uuid )
    item.note = notaInterna
    item.clientnote = notaCliente
    setIfRequiredFieldsFilled(state)
}

//para guardar el nombre del cliente del cart
export function setInfoClient(state, clientData) {
    state.customer.name = clientData.name.trim()
    state.customer.lastname = clientData.lastname.trim()
    state.customer.email = clientData.email.trim()
    state.customer.phone = clientData.phone.trim()
    state.customer.idUser = clientData.idUser
    state.customer.customertype = clientData.customertype
    state.customer.paymentmethod = clientData.paymentmethod
    state.customer.sihotCode = clientData.sihotCode

}

//reemplazar datos de cliente encontrado en consulta por email
export function replaceCustomerData(state, data) {
    const { email, lastname, name, phone, id } = data
    state.customer.email = email.trim()
    state.customer.lastname = lastname.trim()
    state.customer.name = name.trim()
    state.customer.phone = phone.trim()               
    state.customer.idCustomer = id        
}

//set id cliente
export function setIdCustomer(state, idCustomer) {            
    state.customer.idCustomer = idCustomer        
}

//quitar el id del cliente
export function removeIdCustomer(state) {            
    state.customer.idCustomer = null        
}

//para poner valor en la propiedad orderCreated del estado
export function setOrderCreated(state, order) {
    state.orderCreated = order
}

export function setStatusPaymentOrder(state, status) {
    state.orderCreated.statusPayment = status
}

export function setUrlBankPaymentOrder(state, url) {
    state.orderCreated.payment.url = url
}

//limpieza del carrito al terminar la venta 
export function resetCartCustomerBreakdownOrder(state) {
    //limpio cart
    state.cart = []
    //reseteo datos del cliente
    state.customer.name = ''
    state.customer.lastname = ''
    state.customer.email = ''
    state.customer.phone = ''
    state.customer.idCustomer = null
    state.customer.sihotCode = null
    //reinicio el desgloce de totales
    state.breakdown.total = 0.00
    state.breakdown.discountApplied = 0   
    //borro la orden creada 
    state.orderCreated = null      
    state.isAccepetedTermsConditions = false
}

export function clearCart(state) {    
    state.cart = []    
    state.breakdown.total = 0.00
    state.breakdown.discountApplied = 0  
}

export function calculateTotalsItemsCart(state) {
    state.breakdown.total = 0 //state breakdown
    state.cart.forEach(item => {
        const { pax, saleprice, typeDisplay} = item
        // const subTotalChildren = parseFloat(pax.children) * parseFloat(saleprice) //mejorar esto, saleprice para children debe ser desde otra medio
        const qtyFlag = typeDisplay === 'group' ?  1 : parseFloat(pax)

        const total = qtyFlag * parseFloat(saleprice) //mejorar esto, saleprice para adults debe ser desde otra medio        
        item.total = total
        state.breakdown.total += total //state breakdown
    })
}

export function updatePaxInCart(state, data) {    
    const { adults, children } =  data    

    const idxAdult = state.cart.findIndex( item => item.type === 'adults' ) 
    const idxChild = state.cart.findIndex( item => item.type === 'childrens' ) 
    
    if(idxAdult != -1 ){
        state.cart[idxAdult].pax = adults    
    }

    if(idxChild != -1){
        state.cart[idxChild].pax = children    
    }    

    if( children === 0){        
        state.cart = state.cart.filter(item => item.type !== 'childrens' )    
    }

    const existChildItem = state.cart.some(item => item.type === 'childrens')
    
    if( children > 0 && !existChildItem && state.cart.length > 0 ){ //añado el niño pax
        const adultClone = {...state.cart[0]} //clono el adulto
        const existPriceChild =  adultClone.salepriceChild !== '0.00'
        adultClone.uuid = stringAleatorio() 
        adultClone.type = 'childrens'                    
        adultClone.pax = children 
        adultClone.saleprice = adultClone.salepriceChild 
        adultClone.detaildisplay = adultClone.detaildisplayChild 
        adultClone.detailId = adultClone.detailIdChild 

        state.cart = existPriceChild ? [...state.cart, adultClone ] : [...state.cart]       
    }

}



export function setIsAccepetedTermsConditions(state, boolean) {    
    state.isAccepetedTermsConditions = boolean
}
