import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
export const baseUrl = process.env.VUE_APP_BASE_URL_API;

axios.defaults.headers.common['Authorization'] = 'Token '+process.env.VUE_APP_API_TOKEN_EN;
axios.defaults.baseURL = baseUrl;

axios.interceptors.response.use( response =>  {
    // if (response.headers["content-type"]) 'do something'
    // console.log(response.headers)
    return response
})
Vue.use(VueAxios, axios )