const thankYouEs = {
    pageNotFound: 'Página no encontrada.',    
    thePageYouAreLookingForDoesNotExist: 'Lo sentimos, la pagina que estás buscando no existe.',    
    goToOurList: 'Ir a nuestra lista',  
    thankYou: 'Gracias',    
    thanYouForYourPayment: 'Gracias por su pago. Nos pondremos en contacto con usted en breve',
    backToHome: 'Volver a inicio',
    thanYouForYourPreference: 'Gracias por su preferencia, su reserva está pendiente de pago.',
    thanYouForYourPreferencePaymentDenied: 'Gracias por su preferencia, su reserva está pendiente porque el pago fue denegado.',    
}

export default thankYouEs