const itemDetailEn = {
    list: 'Lista',
    detail: 'Detalle',
    checkout: 'Checkout',
    productPolicies: 'Política de productos',
    date: 'Fecha',
    noPersons: 'No. Personas',
    adults: 'Adultos',
    children: 'Niños',  
    total: 'Total',
    taxesAreIncluded: 'Impuesto incluidos',
    proceedToCheckout: 'Proceder a pagar',
    exploreNow: 'Explore Now',
    noResultsFound: 'No se encontraron resultados',
    thePageYouWereLookingForDoesNotExist: 'La página que busca no existe. Vuelva a intentarlo o consulte nuestra lista de resultados.',
    goToOurList: 'Ir a nuestra lista',  
}

export default itemDetailEn