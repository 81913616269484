const bankResponseEs = {
    congratulation: 'Enhorabuena',
    youPaymentWasCompleted: 'Su pago se ha completado',
    successfully: 'con éxito', 
    weAreUnableToProcessYourPayment: 'Lo sentimos, no podemos procesar su pago',
    wouldYouLikeToTryAgain: '¿Quiere intentarlo de nuevo?',
    retryPayment: 'Reintentar pago',    
    email: 'Email', 
    total: 'Total',    
}

export default bankResponseEs