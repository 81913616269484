import Vue from 'vue'
import {  consoleMsgFinally,  } from '@/helpers/helpers'
import { fetchCategories,  fetchCurrencies, fetchHotels, fetchLanguages } from '@/modules/start/store/actions'

export const getInitialContentMarket = async () => {   
    const payloadHotels = { onlyDingusCode: false, all: false, showInWeb: true }                               
    const content = await Promise.all([        
        fetchCategories(), fetchCurrencies(), fetchHotels(payloadHotels), fetchLanguages()
    ])        
    const [ categories,  currencies, hotels, languages ] = content           
    return { categories,  currencies, hotels, languages }
}


export const getInitialContentCheckout = async () => { 
    const payloadHotels = { onlyDingusCode: false, all: false, showInWeb: true }                      
         
    const content = await Promise.all([        
        fetchHotels(payloadHotels), fetchLanguages()
    ])        
    const [ hotels, languages ] = content           
    return { hotels, languages  }
}

//Obtener fechas no disponibles por  producto
export async function fetchBlackoutDatesProduct(context, datos) {    
    const { idP, qty, fini  } = datos
    try {
        const response = await Vue.axios({
            url: '/allotment/notavailable/',
            params: { idP, qty, fini }
        })
        const payload = response && response.data
        return payload
    } catch (error) {
        console.log(error)
    } finally {
        consoleMsgFinally('fetchBlackoutDatesProduct', 'end')
    }
}

export async function checkIfClientExists(context, email) {  
    try {
        const response = await Vue.axios({
            url: `/customers/?email=${email}`,
        })
        const clientData = response && response.data
       return clientData
    } catch (error) {
        console.log(error.response)
    } finally {        
        consoleMsgFinally('checkIfClientExists', 'end')
    }
}

export async function saveCustomer(context, cliente) {    
    try {
        const response = await Vue.axios({
            method: 'POST',
            url: '/customers/',
            data: cliente
        })
        const clientData = response && response.data   
        // console.log(clientData)                     
        return clientData 
    } catch (error) {        
        console.log(error.response)
    } finally {        
        consoleMsgFinally('saveCustomer', 'end')
    }
}


export async function makeSale(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'POST',
            url: '/orders/',
            data: payload
        })
        const respuesta = response && response.data
        return respuesta
    } catch (error) {
        console.log(error.response)
    } finally {
        console.log('makeSale end')
    }
}

export async function retryPaymentOrder(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'POST',
            url: '/paymentretry/',
            data: payload
        })
        const respuesta = response && response.data
        return respuesta
    } catch (error) {
        console.log(error.response)
    } finally {
        console.log('retryPaymentOrder end')
    }
}

export async function checkOrderStatusPayment(context, order) {
    try {
        const response = await Vue.axios({            
            url: '/payment/orderTransactions/',
            params: { order }
        })
        const respuesta = response && response.data
        return respuesta.length > 0 ? respuesta[respuesta.length - 1] : null //obtengo el ultimo registro
    } catch (error) {
        console.log(error.response)
        return error
    } finally {
        console.log('checkOrderStatusPayment end')
    }
}



