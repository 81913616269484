const bankResponseEn = {
    congratulation: 'Congratulations',
    youPaymentWasCompleted: 'Your payment was completed',
    successfully: 'successfully', 
    weAreUnableToProcessYourPayment: 'Sorry, we are unable to process your payment',
    wouldYouLikeToTryAgain: 'Would you like to try again?.',
    retryPayment: 'Retry payment',    
    email: 'Email', 
    total: 'Total',   

}

export default bankResponseEn