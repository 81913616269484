const checkoutEn = {
    completeYouInformation: 'Complete your information',
    beSureToFillInTheRequiredFields: 'Be sure to fill in the required fields',
    name: 'Name',
    surname: 'Lastname',
    email: 'Email',
    phone: 'Phone',
    iAgreeToThese: 'I agree to these',
    termsAndConditions: 'Terms and conditions',
    proceedToPayment: 'Proceed to payment',
    summary: 'Summary',
    date: 'Date',
    totalGuest: 'Total guests',
    adults: 'Adults',
    children: 'Children',  
    total: 'Total',   
    taxesAreIncluded: 'Taxes are included',
    youHaveNotSelectedAnyItem: 'You have not selected any item'
}

export default checkoutEn