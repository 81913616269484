export default {    
    cart:[],    
    breakdown: { total: 0.00, discountApplied: 0 },
    customer: {
        customeragent: '',
        customertype: '',
        email: '',
        hotel: null,
        sihotCode: null,
        idCustomer: null,
        idUser: '',
        language: 1,
        lastname: '',
        name: '',
        paymentlink: '',
        paymentmethod: '',
        paymentreference: '',
        phone: '',
        sellercanalventa: null
    },
    orderCreated: null,  
    canalventa: null,
    puntoventa: null,
    customerCurrency: null,
    isAccepetedTermsConditions: false,   
}