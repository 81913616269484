require('./feather')
require('./boostrap-vue')
require('./vue-axios')
// require('./toastification')
require('./sweet-alerts')
require('./vue-select')
require('./vee-validate')



