// import isAuthenticatedGuard from '../../auth/router/auth-guard'

export default [     
    {
        path: '/results',
        name: 'results',
        component: () => import('@/modules/shop/views/Results'),        
        meta: {            
            gtm: 'list products',
            layout: 'full',
        },
    },
    {
        path: '/item-detail',
        name: 'item-detail',
        component: () => import('@/modules/shop/views/DetailItem'),
        meta: {
            // requiresAuth: true,
            gtm: 'Item detail',
            layout: 'full',
        },
               
    },            
    {
        path: '/checkout',
        name: 'checkout',
        component: () => import('@/modules/shop/views/Checkout'),                
        meta: {
            // requiresAuth: true,            
            gtm: 'checkout',
            layout: 'full',

        },
    },
    {
        path: '/payment',
        name: 'payment',
        component: () => import('@/modules/shop/views/Payment'),                
        meta: {
            // requiresAuth: true,            
            gtm: 'payment',
            layout: 'full',

        },
    },   
    {
        path: '/payment/bank-response/',
        name: 'bank-response',
        component: () => import('@/modules/shop/views/BankResponse'),                
        meta: {           
            gtm: 'bank response page',
            layout: 'full',
        },
    },      
    {
        path: '/thank-you/:order/',
        name: 'thank-you-page',
        component: () => import('@/modules/shop/views/ThankYou'),                
        meta: {           
            gtm: 'thank you page',
            layout: 'full',
        },
    }, 
]