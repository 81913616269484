import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from '@/store'

// para la vista de resultados
import productsEn from '@/data/lang/en/en-products'
import productsEs from '@/data/lang/es/es-products'
// header y footer
import headerFooterEn from '@/data/lang/en/en-header'
import headerFooterEs from '@/data/lang/es/es-header'
// para la vista de detalle de producto
import itemDetailEn from '@/data/lang/en/en-item-detail'
import itemDetailEs from '@/data/lang/es/es-item-detail'
// para la vista de checkout
import checkoutEn from '@/data/lang/en/en-checkout'
import checkoutEs from '@/data/lang/es/es-checkout'
// para la vista de payment
import paymentEn from '@/data/lang/en/en-payment'
import paymentEs from '@/data/lang/es/es-payment'
// para la vista de bank response
import bankResponseEn from '@/data/lang/en/en-bank-response'
import bankResponseEs from '@/data/lang/es/es-bank-response'
// para la vista thank you page
import thankYouEn from '@/data/lang/en/en-thankyou-page'
import thankYouEs from '@/data/lang/es/es-thankyou-page'

Vue.use(VueI18n)

const lang = store.state.auth.lang // tomar el lang inicial que está en el state 
let localeDefault = lang !== '' ? lang : 'en'

const messages = {
    en: {
        products: { ...productsEn },
        headerFooter: { ...headerFooterEn },
        itemDetail: { ...itemDetailEn },
        checkout: { ...checkoutEn },
        payment: { ...paymentEn },
        bankResponse: { ...bankResponseEn },
        thankYou: { ...thankYouEn },

    },
    es:{
        products: { ...productsEs },
        headerFooter:{ ...headerFooterEs },
        itemDetail: { ...itemDetailEs },
        checkout: { ...checkoutEs },
        payment: { ...paymentEs },
        bankResponse: { ...bankResponseEs },
        thankYou: { ...thankYouEs },

    }
  }

export default new VueI18n({
  locale: localeDefault,
  fallbackLocale: 'en',
  messages,
})
