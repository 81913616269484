
export default [
    {
        path: '/login',
        name: 'auth-login',
        component: () => import(/*webpackChunkName: "LoginView"*/'@/modules/auth/views/Login'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            requiresAuth: false,            
        },
    },
    {
        path: '/not-authorized',
        name: 'not-authorized',
        component: () => import(/*webpackChunkName: "NotAuthorizedView"*/'@/modules/auth/views/NotAuthorized'),
        meta: {
            layout: 'full',            
        },
    },
    {
        path: '/not-found',
        name: 'not-found',
        component: () => import(/*webpackChunkName: "Error404View"*/'@/modules/auth/views/Error404'),
        meta: {
            layout: 'full',            
        },
    }     
]