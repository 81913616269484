const productsEn = {
    search: 'Search',
    categories: 'categories',
    whereYouWantToGo: 'Where You Want To Go',
    allDestinations: 'All destinations',
    date: 'Date',
    noPersons: 'No. Persons',
    adultsSearch: 'Adults',
    childrenSearch: 'Children',
    general: 'General',
    startingAt: 'Starting At',
    showMore: 'Show More',
    totalPrice: 'Total Price',
    book: 'Book',
    noProductsFound: 'No Products Found',
    exploreNow: 'Explore Now',
    adults: '{count} adult | {count} adults',
    children: '{count} child | {count} children',

}

export default productsEn