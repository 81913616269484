export function totalDiscountOnAllProducts(state) {
    let desc = 0
    state.cart.forEach((product) => {
        const decimalDiscount = parseFloat(product.discountApplied) / 100
        desc += (parseFloat(product.originalSalePrice) * parseFloat(decimalDiscount) * parseFloat(product.qty))
    });
    return (Math.round(desc * 100) / 100).toFixed(2)
}

export function totalDiscountOrder(state) {

    let sum = 0
    const decimalDiscount = parseFloat(state.breakdown.discountApplied) / 100
    state.cart.forEach((product) => {
        sum += (parseFloat(product.saleprice) * parseFloat(product.qty))
    })
    return (Math.round(sum * decimalDiscount * 100) / 100).toFixed(2)
}

export function isEmptyCart(state) {   
    return state.cart.length == 0  
}
